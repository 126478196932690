export const API_URL = process.env.REACT_APP_baseUrl;
export const GRAPHQL_URL = API_URL + "/graphql";
export const imageDownloadUrl = `https://cdn.sfrglobal.com`;
export const imageUploadUrl = `${API_URL}/api/S3Containers/staging.storage.sportsforce/upload`;
export const videoUploadUrl = `${API_URL}/upload`;
export const geocodeJson = "https://maps.googleapis.com/maps/api/geocode/json";
export const websiteDefaultUrl = process.env.REACT_APP_websiteDefaultURL;
export const zapierApiCall = 'https://hooks.zapier.com/hooks/catch/15010376/3u6s3zw/';
export const heapToken = process.env.REACT_APP_heapToken;
export const awsRegion = process.env.REACT_APP_AWS_REGION;
export const awsVideoSourceAccessKeyId = process.env.REACT_APP_S3_VIDEO_SOURCE_ACCESS_KEY_ID;
export const awsVideoSourceSecretAccessKey = process.env.REACT_APP_S3_VIDEO_SOURCE_SECRET_ACCESS_KEY;
export const awsVideoSourceBucket = process.env.REACT_APP_S3_VIDEO_SOURCE_BUCKET;